.About {
  display: flex;
  height: calc(100vh - 12rem);
  gap: 6rem;
}

.About h1 {
  font-size: 3.7rem !important;
  padding-bottom: 0.25rem;
}

.About p {
  font-size: 1.6rem;
  line-height: 2.6rem;
}

.About-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 2rem 0;
}

.About-Text {
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.About p:first-of-type {
  font-size: 1.7rem;
  line-height: 2.7rem;
  padding-right: 2.3rem;
}

.About-Text * {
  margin-block-start: 0;
  margin-block-end: 0;
}

img {
  width: 36rem;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 2000px) {
  img {
    width: 70rem;
  }
}

@media screen and (max-width: 1500px) {
  .About h1 {
    font-size: 3.4rem !important;
  }
}

@media screen and (max-width: 1350px) {
  .About-Wrapper {
    justify-content: start;
    gap: 3rem;
    padding: 0;
  }

  .About-Text {
    margin-top: 2rem;
    gap: 3rem;
  }

  .About p:first-of-type {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin: -1rem 0;
    padding-right: 0;
  }

  .About p:last-of-type {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  img {
    width: 50% !important;
  }
}

@media screen and (max-width: 1150px) {
  .About {
    height: auto;
    flex-direction: column;
    gap: 1.5rem;
  }

  img {
    width: 100% !important;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 800px) {
  .About h1 {
    font-size: 3rem !important;
  }

  .About p:first-of-type,
  .About p:last-of-type {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }
}

@media screen and (max-width: 700px) {
  .About h1 {
    font-size: 2.5rem !important;
  }

  .About p:first-of-type,
  .About p:last-of-type {
    font-size: 1.2rem;
    line-height: 2.1rem;
  }
}

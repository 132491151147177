.Home {
  display: flex;
  height: 100%;
}

.Home h1 {
  font-size: 5.4rem;
  margin-block-end: auto;
}

.Home-Subtitle {
  margin-block-start: 1.25rem;
  font-size: 2rem;
}

.Home-Wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -14rem;
}

.Home-Footnote {
  position: absolute;
  bottom: 7rem;
  width: 35rem;
  font-size: 1.8rem;
  line-height: 2.6rem;
}

@media screen and (max-width: 800px) {
  .Home h1 {
    font-size: 3.7rem;
  }
  .Home {
    flex-direction: column;
    justify-content: center;
  }
  .Home-Wrapper {
    margin-top: 3rem;
  }
  .Home-Subtitle {
    margin-block-start: 1rem;
    font-size: 1.65rem;
    line-height: 2.2rem;
  }
  .Home-Footnote {
    position: absolute;
    bottom: 7rem;
    width: 25rem;
    font-size: 1.65rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 700px) {
  .Home h1 {
    font-size: 2.5rem;
  }
  .Home {
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .Home-Wrapper {
    margin-top: 3rem;
  }
  .Home-Subtitle {
    margin-block-start: 1rem;
  }
  .Home-Footnote {
    width: 80%;
    bottom: 3rem;
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
}

@media screen and (max-height: 655px) {
  .Home-Footnote {
    bottom: 0;
  }
}

.Resume {
  display: flex;
  gap: 6rem;
}

.Resume-Column:first-of-type {
  flex: 2;
}

.Resume-Column:last-of-type {
  flex: 1;
}

.Resume-Heading {
  font-size: 3rem !important;
}

.Resume h2 {
  font-weight: 500;
}

.Resume ul {
  display: flex;
  flex-direction: column;
  margin-block-start: 0;
  padding-inline-start: 0;
}

.Resume li {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  border-bottom: 1px solid #222;
}

.Resume li:first-child {
  border-top: 1px solid #222;
}

.Resume-Dates {
  text-align: right;
}

.Rating-Circle {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  margin: 0 0.2rem;
  vertical-align: middle;
  border: 1px solid #222;
  border-radius: 2rem;
}

.Rating-Circle-Filled {
  background-color: #0076bacc;
}

@media screen and (max-width: 1100px) {
  .Resume {
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 3rem;
  }

  .Resume-Heading {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .Resume-Dates {
    text-align: left;
    margin-top: -0.5rem;
    opacity: 0.65;
  }

  li:has(.Resume-Dates) {
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1rem;
  }

  .Resume-Heading {
    font-size: 2.5rem !important;
  }
}

.Header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #222;
  font-size: 1.2rem;
}

.Header > a {
  color: #222;
  padding: 0;
}

@media screen and (max-width: 700px) {
  .Header {
    justify-content: center;
  }

  .Header-Name {
    display: none;
  }
}

.App {
  color: #222;
  padding: 3rem;
  height: calc(100% - 6rem);
}

main {
  height: calc(100% - 8rem);
  padding: 2rem;
}

h1 {
  font-weight: 500;
}

a span {
  color: #222;
  border-bottom: 1px solid black;
  padding-bottom: 1px;
}

a {
  text-decoration: none;
}

body {
  background-color: #ede9e5;
  height: 100%;
}

html {
  height: 100%;
}

@media screen and (max-width: 700px) {
  main {
    height: calc(100% - 6rem);
    padding: 0rem;
  }
}

@media screen and (max-width: 700px) {
  .App {
    padding: 1.2rem;
  }

  a span {
    border-bottom: 0;
  }
}

.SocialLinks {
  display: flex;
}

.SocialLinks svg {
  height: 2rem;
  padding: 0 3rem 0 0;
  fill: #222;
}

.SocialLinks svg:hover,
svg:focus {
  fill: #565656;
}

@media screen and (max-width: 1150px) {
  .SocialLinks {
    margin: 2rem 0;
  }
}

.Drawings {
  display: flex;
  flex-wrap: wrap;
}

.Drawings img {
  width: 23%;
  margin-right: 2%;
  margin-bottom: 2%;
  height: auto;
  object-fit: contain;
}

.Gradient {
  position: absolute;
  width: 24rem;
  height: 24rem;
  right: 15rem;
  bottom: 12rem;
  background: linear-gradient(
    143.15deg,
    #00a3ff 8.37%,
    rgba(144, 0, 255, 0.362) 91.49%
  );
  filter: blur(100px);
  z-index: -1;
}

@media screen and (max-width: 800px) {
  .Gradient {
    position: relative;
    width: 20rem;
    height: 20rem;
    right: -18rem;
    bottom: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .Gradient {
    width: 14rem;
    height: 14rem;
    right: -24vw;
    bottom: 0vh;
    background: linear-gradient(
      143.15deg,
      #00a3ff 22.37%,
      rgba(144, 0, 255, 0.763) 91.49%
    );
  }
}

.Error h1 {
  font-size: 5.4rem !important;
  margin-bottom: 2rem;
}

.Error h2 {
  font-weight: 500;
  line-height: 2.5rem;
}

.Error a {
  border-radius: 3rem;
  padding: 10px 32px;
  font-size: 1.2rem;
  text-align: center;
  display: inline-block;
  margin-top: 20px;
  color: #222;
  border: 1px solid #222;
}

@media screen and (max-width: 1100px) {
  .Error h1 {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .Error {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Error a {
    width: 50px;
  }

  .Error h1 {
    font-size: 3.5rem !important;
  }
}

.Navigation {
  display: flex;
  justify-content: space-between;
  margin-right: 16px;
}

.Navigation ul {
  display: flex;
  padding-inline-start: 0;
}

.Navigation li {
  display: block;
  width: 96px;
  margin: 0 12px;
}

.Navigation a {
  border-radius: 3rem;
  padding: 4px 16px;
  text-align: center;
  display: inline-block;
}

.Navigation a[aria-current="page"] {
  border: 1px solid #222;
}

.Navigation a[aria-current="page"] span {
  border-bottom: 0;
}

@media screen and (max-width: 700px) {
  .Navigation {
    justify-content: center;
  }

  .Navigation > ul {
    margin-block-start: 1rem;
    margin-block-end: 1rem;
  }

  .Navigation li {
    margin: auto;
  }
}
